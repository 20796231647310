import { sendEvent } from '@utils/core/track';
import React from 'react';
import styles from './styles.scss';

export default function Logo(props: { title?: React.ReactNode; className?: string }) {
	const { title, className } = props;
	return (
		<div className={`${styles.logo_container} ${className || ''}`}>
			<a href={'/'} onClickCapture={() => {
				sendEvent({ eventId: 'ACW_nav_headlogo' });
			}}>
				<img
					loading="lazy"
					className={styles.logo_icon}
					src="https://web-data.zmlearn.com/image/8UhXesmBNq64iYXEsKpb1N/logo_nav%402x.png"
					alt="air-class"
				/>
			</a>
			{/* <span className={styles.split} />
			<span className={styles.title}>{title}</span> */}
		</div>
	)
}
