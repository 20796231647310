import { history } from '@store/history';
import generatePath from '@utils/generatePath';
import {
	EN_JOIN_US,

	MAIN_HOME
} from 'constants/url';
import Footer from 'containers/ac-containers/ac-footer';
import Header from 'containers/ac-containers/header';
import AppContainer, { AppContainerState } from 'containers/app-container';
import { useModalContext } from 'hooks/useModalContext';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { routerConfig } from './router.config';
import styles from './styles.scss';
import cls from '@utils/css-select';

const cx = cls(styles)
/**
 * 路由组件, 包含头部和底部, 并且渲染路由, 如果路由不存在，则跳转到首页,
 * 这个文件可以当成是一个layout, 可以在这里添加一些公共的组件
 * 组件的实例可以在router.config.tsx中配置，这里只是渲染，不做其他逻辑
 * AppContainer是一个容器，里面处理全局的状态，如果需要其他的全局状态，可以在这里添加
*/

const HeaderComponent = () => {
	const { isShowHeader, isShowMenu } = useModalContext()
	return <Header isShowMenu={isShowMenu} isShowHeader={isShowHeader} />
}

const FooterComponent = () => {
	const { isShowFooter } = useModalContext()
	return <Footer isShowFooter={isShowFooter} />
}

const RenderComponent = (props: { [x: string]: any; children: any }) => {
	const { children, isShowFooter, isShowHeader, isShowMenu, isShow, ...rest } = props
	const { setShowHeader, setShowFooter, setShowMenu } = useModalContext<AppContainerState>()
	const homePath = '/'
	const local = useSelector((state: any) => state.app.local)

	useEffect(() => {
		setShowHeader(isShowHeader)
	}, [isShowHeader, setShowHeader])

	useEffect(() => {
		setShowMenu(isShowMenu)
	}, [isShowMenu, setShowMenu])

	useEffect(() => {
		setShowFooter(isShowFooter)
	}, [isShowFooter, setShowFooter])

	//西语join-us页面recruit页面跳转到首页
	const isJoinUs = local === 'es' && props.location.pathname.includes('/join-us')
	const isRecruit = local === 'es' && props.location.pathname.includes('/recruit')

	// 不显示页面时跳转到首页
	if (!isShow || isJoinUs || isRecruit) {
		return <Redirect to={homePath} />;
	}


	return <div className={`${cx('main-container')} ${ cx(isShowHeader ? 'show-header' : 'not-show-header') }`}>
		{React.cloneElement(children, { ...rest })}</div>
}


const RouterLayout = () => {
	return (
		<Router history={history}>
			<AppContainer>
				<HeaderComponent />
				<Switch>
					{routerConfig.map(({ isShow = true, isShowHeader = true, isShowFooter = true, path, exact = true, component: Component, isShowMenu = true }) => {
						//处理多路由复用页面
						if (Array.isArray(path)) {
							return path.map(p => (
								<Route
									key={p}
									path={p}
									exact={exact}
									component={(props: RouteComponentProps) => {
										return (
											<RenderComponent
												isShowHeader={isShowHeader}
												isShowFooter={isShowFooter}
												isShowMenu={isShowMenu}
												isShow={isShow}
												{...props}
											>
												<Component {...props} />
											</RenderComponent>
										)
									}}
								/>
							));
						}
						return <Route key={path}
							path={path}
							exact={exact}

							component={(props: RouteComponentProps) => {
								return (
									<RenderComponent
										isShowHeader={isShowHeader}
										isShowFooter={isShowFooter}
										isShowMenu={isShowMenu}
										isShow={isShow}
										{...props}
									>
										<Component {...props} />
									</RenderComponent>
								)
							}}
						/>
					})}

					<Route
						render={(props: RouteComponentProps) => {
							const { search } = props.location
							const homePath = '/'
							return <Redirect to={homePath} />;
						}}
					/>

				</Switch>
				<FooterComponent />
			</AppContainer>
		</Router>
	)
};

export default RouterLayout;
