/* eslint-disable react/jsx-no-target-blank */
import { sendEvent } from '@utils/core/track';
import generatePath from '@utils/generatePath';
import { MAIN_HOME } from 'constants/url';
import { useTranslation } from 'hooks/use-translation';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.scss';




function Footer(props: { isShowFooter?: boolean }) {
	const { isShowFooter = true } = props;
	const storageLang = localStorage.getItem('__lang') || 'en'
	const footerRef = useRef<any>()
	const height = document.body.clientHeight // 获取屏幕高度
	const bindHandleScroll = () => {
		if (footerRef.current?.getBoundingClientRect) {
			const top = footerRef.current.getBoundingClientRect().top
			if (top >= 0 && top <= height && footerRef.current.isShow) {
				footerRef.current.isShow = false
				sendEvent({ eventId: 'ACW_scrollbottom' })
			}
		}

	}
	const { t, list, transData } = useTranslation('footer');

	useEffect(() => {
		// 页面滚动监听
		if (footerRef.current) {
			footerRef.current.isShow = true
			window.addEventListener('scroll', debounce(bindHandleScroll, 500))
		}
		return () => {
			// 注销页面滚动监听
			window.removeEventListener('scroll', bindHandleScroll)
			footerRef.current = null
		}
	}, [])

	return (
		isShowFooter ? <footer className={styles['ac-footer']} ref={footerRef}>
			<div className={styles['ac-footer-container']}>
				<div className={styles['ac-footer-list']}>
					<div className={styles['ac-footer-item']} >
						<a className={styles.logo} href={generatePath(MAIN_HOME)} onClickCapture={() => {
							sendEvent({ eventId: 'ACW_footer_logo' })
						}}>
							<img src="https://web-data.zmlearn.com/image/h54UhQrEtsuaycSgJbGoYr/logo_footer%402x.png" alt="" loading='lazy' />
						</a>
						<div className={styles.contact}>
							<a className={styles.facebook} href={storageLang === 'es' ? ' https://www.facebook.com/AirClassLa' : 'https://www.facebook.com/airclassglobal'} target='_blank' onClickCapture={() => {
								sendEvent({ eventId: 'ACW_footer_facebook' })
							}}>
								<img src="https://web-data.zmlearn.com/image/vZESwxjdmLzV2N9ocNcry/icon_footer_facebook%402x.png" alt="" loading='lazy' />
							</a>
							<a className={styles.ins} href='https://www.instagram.com/airclass_global/' target='_blank' onClickCapture={() => {
								sendEvent({ eventId: 'ACW_footer_imstagram' })
							}}>
								<img src="https://web-data.zmlearn.com/image/fNzyZYr5NE18s9NYxsxyxa/icon_footer_ins%402x.png" alt="" loading='lazy' />
							</a>
							<span className={styles.WeChat}>
								<img src="https://web-data.zmlearn.com/image/7XMrEV7sHpDWnPEv2aGCit/WeChat.png" alt="" loading='lazy' />
								<div className={styles.qrcode}>
									<img src="https://web-data.zmlearn.com/image/ogqcjqzQmPWz51aMu2AK92/footer_qrcode.png" alt="" loading='lazy' />
									{
										storageLang === 'en' ?
										<>
											<p>AirClass Kids</p>
											<span>WeChat official account</span>
										</>
										:
										<>
											<p>AirClass Kids</p>
											<span>cuentas oficiales de WeChat</span>
										</>
									}
								</div>
							</span>
							<a className={styles.ins} href='https://twitter.com/airclassglobal' target='_blank' onClickCapture={() => {
								sendEvent({ eventId: 'KidsOWSnew_bottomblogroll_twitter' })
							}}>
								<img src="https://web-data.zmlearn.com/image/iJQziSTmPBVsCBazRVz4PE/twitter.png" alt="" loading='lazy' />
							</a>
							{/* <p className={styles.kidsafeseal}>
								<a href="https://www.kidsafeseal.com/certifiedproducts/airclass.html" target="_blank">
									<img border="0" width="588" alt="Air-class.com and Airclasskids.com is listed by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/20565511671212741751/airclass_extralarge_darktm.png" />
								</a>
							</p> */}
						</div>
					</div>
					{list.map((item: { title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; content: any[] }, index: React.Key | undefined) => {
						return (
							<div className={styles['ac-footer-item']} key={index}>
								<div className={styles['ac-footer-item-title']}>
									<span>{item.title}</span>
								</div>
								<div className={styles['ac-footer-item-content']}>
									<ul>
										{item.content.map((item: { icon: string | undefined; link: any; eventId: any; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }, index: React.Key | undefined) => {
											return (
												<li key={index} >
													{
														!item.icon ?
															<i className={styles['prefix-icon']} /> :
															<img className={styles['prefix-img']} src={item.icon} alt="" loading='lazy' />
													}
													<a target='_blank' href={generatePath(item.link) || undefined} onClick={() => {
														item.eventId && sendEvent({ eventId: item.eventId })
													}}>{item.name}</a>
												</li>
											)
										})}
									</ul>
								</div>
								{

									item.flex.length ? item.flex.map((child: any, index: React.Key | undefined) => {
										return <div className={styles['ac-footer-item-marginTop']} key={index}>
											<div className={styles['ac-footer-item-title']}>
											<span>{child.title}</span>
										</div>
										<div className={styles['ac-footer-item-content']}>
										<ul>
											{child.content.map((item: { icon: string | undefined; link: any; eventId: any; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }, index: React.Key | undefined) => {
												return (
													<li key={index} >
														{
															!item.icon ?
																<i className={styles['prefix-icon']} /> :
																<img className={styles['prefix-img']} src={item.icon} alt="" loading='lazy' />
														}
														<a href={generatePath(item.link) || undefined} onClick={() => {
															item.eventId && sendEvent({ eventId: item.eventId })
														}}>{item.name}</a>
													</li>
												)
											})}
										</ul>
									</div>
										</div>
									})
									: ''
								}
							</div>
						)
					})}
				</div>
				<div className={styles['ac-footer-copyright']}>
					<p></p>
					<div className={styles['ac-footer-copyright-content']}>
						<p>{transData.copyright}</p>
						<p>{transData.ltd}</p>
            {/*<p><a className={styles.link} href="https://beian.miit.gov.cn/" target="_blank">{transData.ltd2}</a></p>*/}
					</div>
				</div>
			</div>
		</footer> : null
	)
}

export default Footer
